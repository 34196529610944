import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Terms from '../components/Terms';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';
import { mixpanelTrack } from '../utils/datalayer/dataLayer';

function IndexPage() {
  const intl = useIntl();
  useEffect(() => mixpanelTrack('terms'));
  return (
    <>
      <NavBar />
      <Terms intl={intl} />
      <Footer />
    </>
  );
}

export default IndexPage;
