import React from 'react';
import { injectIntl } from 'react-intl';

import PropTypes from 'prop-types';
import Container from '../../ui/Container';
import { Section, Headline, Ol, Li, Title, Subtitle } from './styled';
import data from './data';

function Terms({ intl }) {
  return (
    <Section>
      <Container>
        <Headline>{intl.messages['terms.title']}</Headline>

        <Ol>
          {data.map((el) =>
            el.subtitle ? (
              <Subtitle key={el.subtitle}>
                {intl.messages[`terms.${el.subtitle}`]}
              </Subtitle>
            ) : (
              <Li key={el.section}>
                <Title
                  dangerouslySetInnerHTML={{
                    __html: intl.messages[`terms.${el.section}.title`],
                  }}
                />

                {el.list && (
                  <ol>
                    {el.list.map((item) => (
                      <Li
                        key={`${el.section}-${item}`}
                        dangerouslySetInnerHTML={{
                          __html:
                            intl.messages[`terms.${el.section}.item${item}`],
                        }}
                      />
                    ))}
                  </ol>
                )}
              </Li>
            )
          )}
        </Ol>
      </Container>
    </Section>
  );
}

Terms.propTypes = {
  intl: PropTypes.string.isRequired,
};

export default injectIntl(Terms);
