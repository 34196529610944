export default [
  {
    section: 'general',
    list: [1, 2, 3, 4],
  },
  {
    section: 'definitions',
    list: [1, 2, 3, 4, 5, 6],
  },
  { subtitle: 'subtitle0' },
  {
    section: 'eligibility',
    list: [1],
  },
  {
    section: 'international',
    list: [1, 2],
  },
  {
    section: 'restrictions',
    list: [1, 2, 3, 4],
  },
  {
    section: 'noEndorsement',
    list: [1, 2],
  },
  {
    section: 'enforcement',
    list: [1, 2, 3],
  },
  {
    section: 'links',
    list: [1, 2],
  },
  {
    section: 'advertisements',
    list: [1],
  },
  { subtitle: 'subtitle1' },
  {
    section: 'acceptance',
    list: [1, 2, 3, 4, 5],
  },
  {
    section: 'specifications',
    list: [1, 2, 3, 4],
  },
  {
    section: 'device',
    list: [1, 2],
  },
  {
    section: 'warranty',
    list: [1, 2, 3, 4, 5, 6],
  },
  {
    section: 'payment',
    list: [1, 2, 3, 4],
  },
  {
    section: 'delivery',
    list: [1, 2, 3],
  },
  {
    section: 'refund',
    list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  {
    section: 'policy',
    list: [1, 2, 3, 4, 5, 6, 7],
  },
  {
    section: 'property',
    list: [1, 2, 3, 4, 5, 6, 7],
  },
  {
    section: 'liability',
    list: [1, 2, 3],
  },
  {
    section: 'indemnity',
    list: [1, 2],
  },
  {
    section: 'severability',
    list: [1],
  },
  {
    section: 'law',
    list: [1, 2, 3],
  },
];
